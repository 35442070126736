.app-container {
  text-align: center;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: #fbfefe;

  font-family: sans-serif;
}
