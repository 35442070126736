.container {
  width: 100%;

  .body {
    background: #fbfefe;
    height: 100%;
    padding: 1rem;
    max-width: 70rem;
    margin: auto;
    text-align: left;
  }
}
