@import "../../config/styles/mixins.scss";
@import "../../config/styles/colors.scss";

.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 1rem;
  background: linear-gradient(180deg, $bg 0%, #c0edf6 100%);
  width: 100%;
  padding: 1rem 0;
  z-index: 3;

  .hr {
    width: 85%;
    opacity: 0.4;
    background-color: $secondary;
    margin: 1rem 0;
  }

  .curawella {
    display: flex;
    align-items: center;

    P {
      color: $text-dark;
      margin: 0;
    }

    img {
      height: 40px;
      margin: 0 1rem;
    }
  }
}
