@import "../../config/styles/colors.scss";

.sub-header-container {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background: transparent
    linear-gradient(180deg, #c0edf6 0%, #f0fbfd 78%, #fbfefe 100%) 0% 0%
    no-repeat padding-box;
  H1 {
    margin-bottom: 54px;
    color: $text-dark;
    font-weight: 400;
  }
}
